// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from '../../services';
import Routes from '../../routes';
import {
  getReturnConfigRequester,
  generateReturnLabelRequester,
} from './requesters';

// Types
import { APIRequestTypes, Service } from '../../../types/api';
import { IReturn, IReturnResponse, Product } from '../../../types/return';

// Constants
import { NameSpaces } from '../../../../constants/namespaces';
import { SellerOrderProduct } from '../order/models';
import { getReturnConfigMapper } from './mappers';

const NAMESPACE = NameSpaces.RETURNS;

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getReturnByOrderId: build[APIRequestTypes.QUERY]({
    query(arg: { orderId: number; products: SellerOrderProduct[] }) {
      // Get returns by order id
      const returnByOrderIdUrl = Routes.return.byOrderId.replace(
        ':orderId',
        arg?.orderId?.toString()
      );
      return { url: returnByOrderIdUrl };
    },
    transformResponse: ({ content }: IReturnResponse, _meta, { products }) => {
      // Use title and images from new cached service
      return content.map((returnItem: IReturn) => {
        if (!products || products?.length === 0) return { ...returnItem };

        const mergedProducts: Product[] = returnItem?.products.map(
          (orderProduct) => {
            const customerOrderProduct = products.find(
              (item) => item.product_order_id === orderProduct.order_item_id
            );

            return customerOrderProduct
              ? {
                  ...orderProduct,
                  title: customerOrderProduct.title,
                  thumbnail: customerOrderProduct.image,
                }
              : { ...orderProduct };
          }
        );

        return {
          ...returnItem,
          products: [...mergedProducts],
        };
      });
    },
    providesTags: (response?: IReturn[]) =>
      response ? [{ type: NAMESPACE }] : [],
  }),
  generateReturnLabel: build[APIRequestTypes.MUTATION]({
    query: generateReturnLabelRequester,
  }),
  getReturnConfiguration: build[APIRequestTypes.QUERY]({
    query: getReturnConfigRequester,
    transformResponse: getReturnConfigMapper,
  }),
});

export const returnService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const {
  useGetReturnByOrderIdQuery,
  useGetReturnConfigurationQuery,
  useGenerateReturnLabelMutation,
} = returnService;

export type ReturnService = typeof returnService;
