import { Reducer, Slice, SliceCaseReducers } from '@reduxjs/toolkit';
import { OrdersService } from '../api/entities/order/orders';
import { OrderService } from 'store/api/entities/order/order';
import { SellersService } from 'store/api/entities/seller/sellers';
import { TicketService } from 'store/api/entities/ticket';
import { UserService, userState } from 'store/api/entities/user';
import { CustomerService } from 'store/api/entities/customer';
import { MessagesService } from 'store/api/entities/messages';
import { RefundService } from 'store/api/entities/refund';
import { DeliveryService } from 'store/api/entities/delivery/delivery';
import { InvoiceService } from 'store/api/entities/invoice';
import { CustomerOrdersService } from 'store/api/entities/customerOrders/customerOrders';
import { ReturnService } from '../api/entities/return';

type APIServices =
  | UserService
  | TicketService
  | OrdersService
  | OrderService
  | SellersService
  | CustomerService
  | MessagesService
  | DeliveryService
  | RefundService
  | InvoiceService
  | CustomerOrdersService
  | ReturnService;

export const getApiReducersInfo = (apiSlices: APIServices[]) => {
  const reducers: {
    [name: string]: Reducer;
  } = {};

  return apiSlices.reduce((acc, api) => {
    const { reducerPath, reducer } = api;

    acc[reducerPath] = reducer;

    return acc;
  }, reducers);
};

export const getStoreReducersInfo = (
  slices: Slice<userState, SliceCaseReducers<userState>, string>[]
) => {
  const reducers: {
    [name: string]: Reducer;
  } = {};

  return slices.reduce((acc, slice) => {
    const { name, reducer } = slice;

    acc[name] = reducer;

    return acc;
  }, reducers);
};
