import Routes from 'store/api/routes';
import { RequestArgs, RequestMethods } from 'store/types/api';
import { InstructionDetails } from '../../../../types/return';

export type GenerateReturnLabelRequest = {
  return_id: string;
  body?: Partial<
    Pick<
      InstructionDetails,
      'home_collection_address' | 'home_collection_pick_up'
    >
  >;
};

export const generateReturnLabelRequester = ({
  return_id,
  body,
}: GenerateReturnLabelRequest) => {
  return {
    url: `${Routes.return.returnLabel.replace(':returnId', return_id)}`,
    method: RequestMethods.POST,
    body,
  } as RequestArgs;
};
