import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ReturnInstructionAddress,
  ReturnInstructionAddressErrors,
} from './models';
import { ReturnConfiguration } from './models';

export interface FormState {
  values: ReturnInstructionAddress;
  errors: ReturnInstructionAddressErrors;
}

const initialState: FormState = {
  values: {
    name: '',
    lastName: '',
    companyName: '',
    address: '',
    address2: '',
    country: '',
    city: '',
    zipCode: '',
    phone: '',
    collectionDate: '',
    collectionTime: '',
  },
  errors: {
    name: true,
    lastName: true,
    companyName: false, // No validation needed
    address: true,
    address2: false, // always false
    country: true,
    city: true,
    zipCode: true,
    phone: true,
    collectionDate: true,
    collectionTime: true,
  },
};

export const instructionAddressFormSlice = createSlice({
  name: 'instructionAddressForm',
  initialState,
  reducers: {
    setField: (
      state,
      action: PayloadAction<{
        field: keyof ReturnInstructionAddress;
        value: string;
      }>
    ) => {
      const { field, value } = action.payload;
      state.values[field] = value;
    },
    setErrors: (state, action: PayloadAction<Record<string, boolean>>) => {
      return {
        ...state,
        errors: { ...state.errors, ...action.payload },
      };
    },
    setFields: (state, action: PayloadAction<ReturnConfiguration>) => {
      const { collectionDate, collectionTime, deliveryAddress } =
        action.payload;
      const mappedValues: ReturnInstructionAddress = {
        collectionDate: collectionDate.min,
        name: deliveryAddress.firstName,
        lastName: deliveryAddress.lastName,
        companyName: deliveryAddress.companyName ?? '',
        address: deliveryAddress.address,
        address2: deliveryAddress.address2 ?? '',
        country: deliveryAddress.country,
        city: deliveryAddress.city,
        zipCode: deliveryAddress.zipCode,
        phone: deliveryAddress.PhoneNumber,
        collectionTime: '',
      };
      Object.assign(state.values, mappedValues);
      Object.keys(state.errors).forEach((key) => {
        state.errors[key as keyof ReturnInstructionAddressErrors] = false;
      });
      state.errors.collectionTime = collectionTime;
    },
    resetForm: () => initialState,
  },
});

export const { setField, setErrors, resetForm, setFields } =
  instructionAddressFormSlice.actions;
