import { combineReducers } from '@reduxjs/toolkit';
import ordersService from '../api/entities/order/orders';
import orderService from 'store/api/entities/order/order';
import sellersService from 'store/api/entities/seller/sellers';
import ticketService from 'store/api/entities/ticket';
import { userService, userSlice } from 'store/api/entities/user';
import customerService from 'store/api/entities/customer';
import messagesService from 'store/api/entities/messages';
import deliveryService from 'store/api/entities/delivery/delivery';
import refundService from 'store/api/entities/refund/refund';
import invoiceService from 'store/api/entities/invoice';
import { getApiReducersInfo, getStoreReducersInfo } from './utils';
import customerOrdersService from 'store/api/entities/customerOrders/customerOrders';
import {
  instructionAddressFormSlice,
  returnService,
} from 'store/api/entities/return';

const RTKReducers = getApiReducersInfo([
  ticketService,
  userService,
  ordersService,
  orderService,
  sellersService,
  customerService,
  messagesService,
  invoiceService,
  deliveryService,
  refundService,
  customerOrdersService,
  returnService,
]);

const StoreReducers = getStoreReducersInfo([userSlice]);

export default combineReducers({
  ...RTKReducers,
  ...StoreReducers,
  instructionAddressForm: instructionAddressFormSlice.reducer,
});
