import { SellerOrder } from 'store/api/entities/order/models';
import { GetSellerOrderByIdResponse } from 'store/api/entities/order/requesters/getSellerOrderById.contract';
import { sellerOrderStatusMapper } from 'store/api/entities/order/mappers/sellerOrderStatusMapper';
import { priceMapper } from 'store/api/entities/shared/mappers';

export const getSellerOrderByIdMapper = ({
  content,
}: GetSellerOrderByIdResponse): SellerOrder => {
  return {
    id: content.id,
    reference_number: content.reference_number,
    customer_order_id: content.customer_order_id || '', // TODO: as in v3 this could be null, we have to check if this is not breaking anything
    customer_order_reference: content.customer_order_reference || '',
    date: content.date,
    country_iso: content.country_iso,
    is_mmf: content.is_mmf,
    is_mfx: content.is_mfx,
    status: sellerOrderStatusMapper(content.status),
    total_price: priceMapper(content.total_price, content.currency),
    total_price_vat: priceMapper(content.total_price_vat, content.currency),
    shipping_price_vat_rate: priceMapper(
      content.shipping_price_vat_rate,
      content.currency
    ),
    total_price_excluding_vat: priceMapper(
      content.total_price_excluding_vat,
      content.currency
    ),
    total_discount: priceMapper(content.total_discount, content.currency),
    shipping_price: priceMapper(content.shipping_price, content.currency),
    shipping_price_excluding_vat: priceMapper(
      content.shipping_price_excluding_vat,
      content.currency
    ),
    shipping_discount: priceMapper(
      content.shipping_discount || 0,
      content.currency
    ),
    ...(content.manomano_coupon_on_product && {
      manomano_coupon_on_product: priceMapper(
        content.manomano_coupon_on_product,
        content.currency
      ),
    }),
    ...(content.manomano_coupon_on_shipping && {
      manomano_coupon_on_shipping: priceMapper(
        content.manomano_coupon_on_shipping,
        content.currency
      ),
    }),
    ...(content.manomano_delivery_discount && {
      manomano_delivery_discount: priceMapper(
        content.manomano_delivery_discount,
        content.currency
      ),
    }),
    ...(content.seller_coupon_on_product && {
      seller_coupon_on_product: priceMapper(
        content.seller_coupon_on_product,
        content.currency
      ),
    }),
    ...(content.total_amount_paid_by_the_customer && {
      total_amount_paid_by_the_customer: priceMapper(
        content.total_amount_paid_by_the_customer,
        content.currency
      ),
    }),
    currency: content.currency,
    products: content.products.map((product) => ({
      id: product.id,
      product_order_id: product.product_order_id,
      title: product.title || '',
      image: product.image || '',
      description: product.description || '',
      me_reference: product.me_reference,
      price_ttc: priceMapper(product.price_ttc, product.currency),
      price_htc: priceMapper(product.price_htc, product.currency),
      price_total_ttc: priceMapper(product.price_total_ttc, product.currency),
      ...(product.total_amount_paid_by_the_customer && {
        total_amount_paid_by_the_customer: priceMapper(
          product.total_amount_paid_by_the_customer,
          product.currency
        ),
      }),
      currency: product.currency,
      seller: {
        id: product.seller.id,
        name: product.seller.name || '',
      },
      seller_sku: product.seller_sku,
      order_item_quantity: product.order_item_quantity,
      logistics:
        (product.logistics && {
          logistic_id: product.logistics.logistic_id,
          ean: product.logistics.ean,
          height: product.logistics.height || 0,
          weight_gross: product.logistics.weight_gross || 0,
          weight_net: product.logistics.weight_net || 0,
          width: product.logistics.width || 0,
          depth: product.logistics.depth || 0,
          volume: product.logistics.volume || 0,
        }) ||
        undefined,
    })),
    customer_id: content.customer_id,
  };
};
