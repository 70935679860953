import { GetReturnConfigurationResponse } from '../requesters';
import { ReturnConfiguration } from '../models';

export const getReturnConfigMapper = (
  response: GetReturnConfigurationResponse
): ReturnConfiguration | undefined => {
  if (!response) return undefined;
  const { content } = response;

  return {
    collectionDate: {
      min: content.collection_date.min,
      max: content.collection_date.max,
    },
    collectionTime: content.collection_time,
    deliveryAddress: {
      firstName: content.delivery_address.first_name,
      lastName: content.delivery_address.last_name,
      companyName: content.delivery_address.company_name,
      address: content.delivery_address.address,
      address2: content.delivery_address.address2,
      zipCode: content.delivery_address.zip_code,
      city: content.delivery_address.city,
      country: content.delivery_address.country,
      PhoneNumber: content.delivery_address.phone_number,
    },
  };
};
