import { NameSpaces } from 'constants/namespaces';
import { ServiceProviders } from 'store/types/api';
import { RTKQuery } from '../base';

const {
  ORDERS,
  SELLERS,
  CUSTOMER,
  MESSAGES,
  INVOICE,
  CONCRETE_GUARANTEE,
  REFUND,
  COUPONS,
  RETURNS,
  CORT,
} = NameSpaces;

const msfApi = RTKQuery.createRTKQueryApi({
  serviceProvider: ServiceProviders.MSF,
  reducerPath: 'msfApi',
  tagTypes: [
    ORDERS,
    SELLERS,
    CORT,
    CUSTOMER,
    MESSAGES,
    INVOICE,
    CONCRETE_GUARANTEE,
    REFUND,
    COUPONS,
    RETURNS,
    `${CONCRETE_GUARANTEE}-motives`,
    `${CONCRETE_GUARANTEE}-solutions`,
    `${CONCRETE_GUARANTEE}-extension-reasons`,
    `${CONCRETE_GUARANTEE}-refuse-reasons`,
    `${CONCRETE_GUARANTEE}-appeal-reasons`,
  ],
});

export default msfApi;
