// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from 'store/api/services';
import Routes from 'store/api/routes';

// Types
import { APIRequestTypes, RequestMethods, Service } from 'store/types/api';
import {
  IInvoice,
  IInvoiceClaimDTOResponse,
  IInvoiceResponse,
  setClaimArgs,
} from 'store/types/invoice';

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getInvoices: build[APIRequestTypes.QUERY]({
    query(arg: { orderId: number }) {
      const url = Routes.invoice.invoices.replace(
        ':orderId',
        arg?.orderId?.toString()
      );

      return { url };
    },
    transformResponse: ({ content }: IInvoiceResponse) => content,
  }),
  setClaim: build[APIRequestTypes.MUTATION]({
    query(arg: setClaimArgs) {
      const orderId = arg.orderId.toString();
      const sellerId = arg.sellerId.toString();
      const url = Routes.invoice.claims
        .replace(':orderId', orderId)
        .replace(':sellerId', sellerId);
      return { url, method: RequestMethods.POST };
    },
    async onQueryStarted({ orderId }, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        const response = (data as IInvoiceClaimDTOResponse).content;

        dispatch(
          invoiceService.util.updateQueryData(
            'getInvoices',
            {
              orderId,
            },
            (draft = {} as IInvoice) => {
              const { order_id, seller_id } = response;

              // Draft is the whole invoice data, but we just want
              // to update the claims for the specific order and seller
              const updatedClaims =
                draft.claims?.map((claim) => {
                  const {
                    invoice_claim: {
                      seller_id: currentStateSellerId,
                      order_id: currentStateOrderId,
                    },
                  } = claim;

                  if (
                    currentStateOrderId === order_id &&
                    currentStateSellerId === seller_id
                  ) {
                    // Update the claim_dto with the new data
                    const updatedClaim = Object.assign(
                      claim.invoice_claim,
                      response
                    );

                    claim.invoice_claim = updatedClaim;
                  }

                  return claim;
                }) ?? [];

              // We can mutate draft cause Redux Toolkit uses immer, so it's safe
              return Object.assign(draft, { claims: updatedClaims });
            }
          )
        );
        // eslint-disable-next-line no-empty
      } catch {}
    },
  }),
});

const invoiceService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const { useGetInvoicesQuery, useSetClaimMutation } = invoiceService;

export type InvoiceService = typeof invoiceService;

export default invoiceService;
